import React from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import Navbar from "../components/Navbars/Navbar";
import CardsFooter from "../components/Footers/CardsFooter";
import { Badge, Button, Card, CardBody, FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import pic1 from "./../assets/img/theme/heavy.svg";
import landingPic from "./../assets/img/brand/landing2.jpg";
import "../assets/scss/Landing.scss";

import CardCategory from "../components/Cards/CardCategory.js";
import cablePic from "./../assets/img/brand/cable2.png";
import upsPic from "./../assets/img/brand/ups.png";
import threeMPic from "./../assets/img/brand/3m.png";
import schneiderPic from "./../assets/img/brand/schneider.png";
import accessoriesPic from "./../assets/img/brand/skun.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { showModalContact } from "../redux/actions/Index";
import Product from "../components/Cards/CardProductCable";
import AxiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";
import ModalContact from "../components/Modals/ModalContact";
import * as emailjs from "emailjs-com";
import SweetAlert from "react-bootstrap-sweetalert";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 2,
	},
};

function ListPemesanan(props) {
	return (
		<li className="py-2">
			<div className="d-flex align-items-center">
				<div>
					<Badge className="badge-circle mr-3" color="dark">
						{props.nomer}
					</Badge>
				</div>
				<div>
					<h6 className="mb-0">{props.text}</h6>
				</div>
			</div>
		</li>
	);
}

class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listCable: null,
			feedback_name: "",
			feedback_email: "",
			feedback_message: "",
			alert: null,
		};
	}

	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
		this.fetchData();
	}

	fetchData() {
		const url = "/cable";
		AxiosInstance.get(url)
			.then((response) => {
				this.setState({ listCable: response.data });
				// console.log(response.data);
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	handleSubmitFeedback(e) {
		e.preventDefault();
		const { feedback_name, feedback_email, feedback_message } = this.state;
		let templateParams = {
			customer_name: feedback_name,
			customer_email: feedback_email,
			message: feedback_message,
		};
		emailjs.send("default_service", "template_1a2rv98", templateParams, "user_KSvlU81dTkCDftxr7tJxj");
		this.resetForm();
		this.handleAlertFeedback();
	}

	resetForm() {
		this.setState({
			feedback_name: "",
			feedback_email: "",
			feedback_message: "",
		});
	}

	handleAlertFeedback() {
		const getAlert = () => (
			<SweetAlert success title="Terima kasih atas masukan Anda" onConfirm={() => this.setState({ alert: null })} onCancel={() => this.setState({ alert: null })}>
				Masukan anda sangat berarti bagi kami
			</SweetAlert>
		);

		this.setState({
			alert: getAlert(),
		});
	}

	handleChange = (param, e) => {
		this.setState({ [param]: e.target.value });
	};

	render() {
		// console.log(this.state);
		return (
			<div>
				<Helmet>
					<title>Distributor Kabel Metal Surabaya, Jual UPS | PT Sentratek Metalindo</title>
					<meta name="description" content="PT Sentratek Metalindo adalah distributor kabel metal di Kota Surabaya. PT Sentratek Metalindo menerima pesanan kabel metal, UPS, dan produk kelistrikan lainnya. Distributor Kabel Surabaya | Beli Kabel Metal Surabaya | Kable Metal | Rods and Conductor | Aluminium Rods | Copper Rods | Aluminium Conductors | Copper Conductors | Steel Strand | Low Voltage Power" />
					<meta name="generator" content="kabel metal indonesia, sentratek metalindo, distributor kabel metal indonesia, pt sentratek metalindo" />
				</Helmet>
				<Navbar />
				<ModalContact />
				<main ref="main">
					<section className="section section-lg section-shaped pb-250">
						<div className="shape shape-style-1" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
							<img src={landingPic} className="landing-pic" alt=""></img>
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
						<Container className="py-lg-md d-flex">
							<div className="col px-0">
								<Row>
									<Col lg="12" className="text-center textLanding">
										<h2 className="display-1 text-white">
											Selamat Datang <br />
											di PT. Sentratek Metalindo Surabaya
										</h2>
										<h5 className="text-white mb-5">Kami hadir disini untuk membantu Anda menemukan produk kabel sesuai kebutuhan Anda. Anda akan lebih mudah memilih produk dengan website ini. Lakukan pemesanan dan Anda akan kami hubungi.</h5>
										<div className="btn-wrapper">
											<Button className="btn-white btn-icon mb-3 mb-sm-0 ml-1" color="default" href="/#categoryProduct">
												<span className="btn-inner--text">Pesan Sekarang</span>
												<span className="btn-inner--icon ml-1">
													<i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
												</span>
											</Button>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
						{/* SVG separator */}
						<div className="separator separator-bottom separator-skew">
							<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
								<polygon className="fill-white" points="2560 0 2560 100 0 100" />
							</svg>
						</div>
					</section>

					<section className="section section-lg pt-lg-0 " id="categoryProduct">
						<Container>
							<Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={2000} infinite={true}>
								<div className="mx-3 pb-3">
									<CardCategory nama={"Kabel"} img={cablePic} link={"/cable"} />
								</div>
								<div className="mx-3 pb-3">
									<CardCategory nama={"UPS APC By Schneider"} img={upsPic} link={"/ups"} />
								</div>
								<div className="mx-3 pb-3 ">
									<CardCategory nama={"3M"} img={threeMPic} link={"/3m"} />
								</div>
								<div className="mx-3 pb-3 ">
									<CardCategory nama={"Schneider"} img={schneiderPic} link={"/schneider"} />
								</div>
								<div className="mx-3 pb-3 ">
									<CardCategory nama={"Aksesoris"} img={accessoriesPic} link={"/accessories"} />
								</div>
							</Carousel>
						</Container>
					</section>

					<section className="section section-lg mt--100" id="howToOrder">
						<Container>
							<div className="text-center py-4 titleSection">
								<h1 className="title-category">Cara Pemesanan</h1>
								<h1 className="subtitle-category">Sederhana dan mudah dimengerti</h1>
							</div>
							<Row className="row-grid align-items-center">
								<Col className="order-md-2" md="6">
									<img alt="..." className="img-fluid floating" src={pic1} />
								</Col>
								<Col className="order-md-1" md="6">
									<div className="pr-md-5">
										<p>Kami akan bantu Anda pemesanan produk pada PT. Sentratek Metalindo Surabaya. Untuk langkah pemesanan, Anda cukup mengikuti langkah mudah berikut ini:</p>
										<ul className="list-unstyled mt-3">
											<ListPemesanan nomer={"1"} text={"Cari Produk"} />
											<ListPemesanan nomer={"2"} text={"Tambah Produk"} />
											<ListPemesanan nomer={"3"} text={"Checkout"} />
											<ListPemesanan nomer={"4"} text={"Dapatkan Email Pemesanan"} />
											<ListPemesanan nomer={"5"} text={"Anda akan segera dihubungi oleh Tim Kami"} />
											<li>
												Untuk lebih lengkapnya, dapat menghubungi Marketing kami{" "}
												<a href="http://wa.me/6281222227312" target="_blank" rel="noreferrer">
													disini
												</a>
												.
											</li>
										</ul>
									</div>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="section section-lg pt-lg-0 " id="productLanding">
						<Container>
							<div className="text-center py-4 titleSection">
								<h1 className="title-category">Katalog</h1>
								<h1 className="subtitle-category">Contoh Beberapa Produk Kami</h1>
							</div>
							<Row className="row-grid justify-content-md-center p-3">
								{this.state.listCable === null ? (
									<div className="text-center">
										<img src={LoadingPic} alt="..." />
									</div>
								) : (
									Object.values(this.state.listCable)
										.slice(0, 8)
										.map((item) => <Product cable={item} key={item.id} type={"colProductLanding"} />)
								)}
							</Row>
						</Container>
					</section>

					<section className="section section-lg mt--100" id="featuresUs">
						<Container>
							<div className="text-center py-4 titleSection">
								<h1 className="title-category">Fitur Kami</h1>
								<h1 className="subtitle-category">Buat Pemesanan jadi mudah dan cepat</h1>
							</div>
							<Row className="row-grid align-items-start features py-4">
								<Col lg="4 " className="fiturKami">
									<Row>
										<Col lg="auto">
											<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
												<i className="fa fa-file-text-o" />
											</div>
										</Col>
										<Col>
											<div>
												<div className="features-title">Deskripsi Produk</div>
												<div className="features-content">Deskripsi produk lengkap</div>
											</div>
										</Col>
									</Row>
								</Col>
								<Col lg="4" className="fiturKami">
									<Row>
										<Col lg="auto">
											<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
												<i className="fa fa-info" />
											</div>
										</Col>
										<Col>
											<div>
												<div className="features-title">Mudah</div>
												<div className="features-content">Tidak terbatas ruang dan waktu</div>
											</div>
										</Col>
									</Row>
								</Col>
								<Col lg="4" className="fiturKami">
									<Row>
										<Col lg="auto">
											<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
												<i className="fa fa-percent " />
											</div>
										</Col>
										<Col>
											<div>
												<div className="features-title">Diskon</div>
												<div className="features-content">Potongan harga langsung</div>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="section section-lg" id="lineLanding">
						<div className="parallax">
							<Row className="p-6 text-center">
								<Col>
									<h1>Segera Pesan Sekarang</h1>
								</Col>
								<Col>
									<Button className="btn-white btn-icon mb-3 mb-sm-0 ml-1" color="default" href="/#categoryProduct">
										<span className="btn-inner--text">Pesan Sekarang</span>
										<span className="btn-inner--icon ml-1">
											<i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
										</span>
									</Button>
								</Col>
							</Row>
						</div>
					</section>

					<section className="section section-lg pt-lg-0 pb-lg-0 " id="feedbackLanding">
						<Container>
							<Row className="justify-content-center mt-10">
								<Col lg="8">
									<Card className="bg-gradient-secondary shadow">
										<CardBody className="p-lg-5">
											<h4 className="mb-1">Beri Kami Feedback</h4>
											<p className="mt-0">Karena masukan Anda penting untuk Kami.</p>
											<FormGroup
												className={classnames("mt-5", {
													focused: this.state.nameFocused,
												})}
											>
												<InputGroup className="input-group-alternative">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ni ni-user-run" />
														</InputGroupText>
													</InputGroupAddon>
													<Input placeholder="Nama Anda" type="text" onFocus={() => this.setState({ nameFocused: true })} onBlur={() => this.setState({ nameFocused: false })} value={this.state.feedback_name} onChange={this.handleChange.bind(this, "feedback_name")} />
												</InputGroup>
											</FormGroup>
											<FormGroup
												className={classnames({
													focused: this.state.emailFocused,
												})}
											>
												<InputGroup className="input-group-alternative">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ni ni-email-83" />
														</InputGroupText>
													</InputGroupAddon>
													<Input placeholder="Alamat Email Anda" type="email" onFocus={() => this.setState({ emailFocused: true })} onBlur={() => this.setState({ emailFocused: false })} value={this.state.feedback_email} onChange={this.handleChange.bind(this, "feedback_email")} />
												</InputGroup>
											</FormGroup>
											<FormGroup className="mb-4">
												<Input className="form-control-alternative" placeholder="Tulis pesan ..." rows="4" type="textarea" value={this.state.feedback_message} onChange={this.handleChange.bind(this, "feedback_message")} />
											</FormGroup>
											<div>
												<Button block className="btn-round" color="default" size="lg" type="button" onClick={(e) => this.handleSubmitFeedback(e)}>
													Kirim Feedback
												</Button>
											</div>
										</CardBody>
									</Card>
									{this.state.alert}
								</Col>
							</Row>
						</Container>
					</section>
				</main>
				<CardsFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		modalContact: state.modalContact,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalContact: (status) => dispatch(showModalContact(status)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Landing);
