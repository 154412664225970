import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Badge } from "reactstrap";
import "react-medium-image-zoom/dist/styles.css";
import fireIcon from "../../assets/img/icons/fire.svg";
import "../../assets/scss/Card.scss";
import Price from "../Price";

class CardProduct extends React.Component {
	render() {
		const baseURL = process.env.REACT_APP_BACKEND_IMAGES;
		const cable = this.props.cable;
		return (
			<div className={`mb-3 ${this.props.type}`}>
				<Card className="rounded shadow border-0 cardProduct card__one">
					<a href={`/cable/${cable.id}`} tag={Link}>
						{cable.isPopular === 1 ? (
							<span className="notify-badge">
								<div className=" icon-shape text-white p-1 popularIcon img-hover-zoom">
									<img src={fireIcon} className="img-fluid icon-notify" alt=""></img>
								</div>
							</span>
						) : null}

						{cable.image !== null ? (
							<div className="previewProduct">
								<img alt="" src={`${baseURL}/${cable.image}`} className="img-fluid" />
							</div>
						) : (
							<div className="previewProduct d-flex align-items-center justify-content-center">
								<i>Tidak ada foto produk</i>
							</div>
						)}

						<CardBody className="py-1 px-2 ">
							<div style={titleStyle} className="font-weight-600 crop-text-2 ">
								{cable.name}
							</div>
							<Price price={cable.price} discount={cable.discount} cutOff={cable.cutOff} />
							<div className="p-0">
								{/* {cable.discount !== null ? (
									<Badge color="primary" pill className="p-1" style={{ fontSize: "0.6rem" }}>
										Harga Khusus
									</Badge>
								) : null} */}
								{cable.fireResist === 1 ? (
									<Badge color="danger " pill className="p-1 mr-1" style={{ fontSize: "0.6rem" }}>
										<i className="fa fa-fire mr-1" aria-hidden="true"></i>
										Fire Resistance
									</Badge>
								) : null}
								{/* {cable.isPopular !== 0 ? (
									<Badge color="warning" pill className="p-1 mr-1" style={{ fontSize: "0.6rem" }}>
										Populer
									</Badge>
								) : null} */}
							</div>
						</CardBody>
					</a>
				</Card>
			</div>
		);
	}
}

export default CardProduct;

const titleStyle = {
	fontSize: "0.9rem",
};
