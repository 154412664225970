import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Landing from "./views/Landing";
import Landing_Track from "./views/Landing_Track";
import ListCable from "./views/Cable/ListCable";
import ListUps from "./views/UPS/ListUps";
import List3M from "./views/3M/List3M";
import ListSchneider from "./views/Schneider/ListSchneider";
import ListAccessories from "./views/Accessories/ListAccessories";

import DetailCable from "./views/Cable/DetailCable";
import DetailUps from "./views/UPS/DetailUps";
import Detail3M from "./views/3M/Detail3M";
import DetailSchneider from "./views/Schneider/DetailSchneider";
import DetailAccessories from "./views/Accessories/DetailAccessories";

import DetailOrder from "./views/Cart";
import About from "./views/About";
import CekStatus from "./views/CekStatus";

import NotFound from "./views/NotFound";
import MessageSuccess from "./views/MessageSuccess";

export default function App(props) {
	return (
		<BrowserRouter basename="/">
			<Switch>
				<Route path="/" exact component={Landing} />
				<Route path="/track/:code/:destination" exact component={Landing_Track} />
				<Route path="/cable" exact component={ListCable} />
				<Route path="/ups" exact component={ListUps} />
				<Route path="/3m" exact component={List3M} />
				<Route path="/schneider" exact component={ListSchneider} />
				<Route path="/accessories" exact component={ListAccessories} />

				<Route path="/notfound" exact component={NotFound} />

				<Route path="/cable/:idProduct" exact component={DetailCable} />
				<Route path="/ups/:idProduct" exact component={DetailUps} />
				<Route path="/3m/:idProduct" exact component={Detail3M} />
				<Route path="/schneider/:idProduct" exact component={DetailSchneider} />
				<Route path="/accessories/:idProduct" exact component={DetailAccessories} />

				<Route path="/cart" component={DetailOrder} />
				<Route path="/about" component={About} />
				<Route path="/cek-status/" exact component={CekStatus} />
				<Route path="/cek-status/:kode" exact component={CekStatus} />

				<Route path="/order/success" component={MessageSuccess} />

				<Route path="*" component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
}
