import React, { Component } from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/CardsFooter";
import { Button, FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Form, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { addName, addEmail, addPhone, setWhatsapp, checkProduct, updateQuantity, addCompany, deleteCart, updateNoted, clearSession, checkAllProduct } from "../redux/actions/Index";
import "./../assets/css/ListProduct.css";
import ModalContact from "../components/Modals/ModalContact";
import { Helmet } from "react-helmet";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, Redirect } from "react-router-dom";
import "../assets/scss/Navbar.scss";
import Price from "../components/Price";
import AxiosInstance from "../services/AxiosService";
import LoadingOverlay from "react-loading-overlay";

class DetailOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alertSubmit: null,
			checkAll: true,
			loading: false,
			taskDone: false,
		};
	}

	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
	}

	handleSubmit(e) {
		e.preventDefault();
		const getAlert = () => (
			<SweetAlert custom showCancel confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" title="Checkout" onConfirm={() => this.checkData()} onCancel={() => this.setState({ alertSubmit: null })} focusCancelBtn>
				Apakah ingin checkout pesanan Anda?
			</SweetAlert>
		);

		this.setState({
			alertSubmit: getAlert(),
		});
	}

	validatePhoneNumber(input_str) {
		var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		return re.test(input_str);
	}

	async checkData() {
		let i;
		let itemValid = this.props.cart.filter((item) => item.check === true);
		if (itemValid.length === 0) {
			const getAlert = () => (
				<SweetAlert error title="Proses Gagal" onConfirm={() => this.setState({ alertSubmit: null })} onCancel={() => this.setState({ alertSubmit: null })}>
					Keranjang Anda Kosong!
				</SweetAlert>
			);

			this.setState({
				alertSubmit: getAlert(),
			});
			return false;
		}
		for (i = 0; i < itemValid.length; i++) {
			let item = itemValid[i];

			if (item.detail.minPurchase !== null && item.detail.minPurchase !== undefined) {
				if (item.quantity < item.detail.minPurchase || item.quantity % item.detail.minPurchase !== 0) {
					const getAlert = () => (
						<SweetAlert error title="Proses Gagal" onConfirm={() => this.setState({ alertSubmit: null })} onCancel={() => this.setState({ alertSubmit: null })}>
							Cek kembali jumlah minimal pemesanan produk
						</SweetAlert>
					);

					this.setState({
						alertSubmit: getAlert(),
					});
					return false;
				}
			}
		}
		if (this.validatePhoneNumber(this.props.user.phone)) {
			this.setState({ alertSubmit: null, loading: true });
			this.sendingData();
		} else {
			const getAlert = () => (
				<SweetAlert error title="No. Handphone Salah" onConfirm={() => this.setState({ alertSubmit: null })} onCancel={() => this.setState({ alertSubmit: null })}>
					Mohon masukan kembali no. handphone Anda
				</SweetAlert>
			);
			this.setState({
				alertSubmit: getAlert(),
			});
		}
	}

	groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}

	sendingData() {
		const url = `/orders/create`;
		const cartGrouped = this.groupBy(
			this.props.cart.filter((item) => item.check === true),
			(item) => item.type
		);
		const cartCable = cartGrouped.get("cable") === undefined ? [] : cartGrouped.get("cable");
		const cartUps = cartGrouped.get("ups") === undefined ? [] : cartGrouped.get("ups");
		const cart3M = cartGrouped.get("3m") === undefined ? [] : cartGrouped.get("3m");
		const cartSchneider = cartGrouped.get("schneider") === undefined ? [] : cartGrouped.get("schneider");
		const cartAccessories = cartGrouped.get("accessories") === undefined ? [] : cartGrouped.get("accessories");

		const dataToPost = {
			cartCable: cartCable,
			cartUps: cartUps,
			cart3M: cart3M,
			cartSchneider: cartSchneider,
			cartAccessories: cartAccessories,
			user: this.props.user,
		};

		AxiosInstance.post(url, dataToPost)
			.then((response) => {
				this.sendEmail(dataToPost, response.data);
				setTimeout(() => this.setState({ loading: false, taskDone: true }), 3000);
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	onCheckListAll() {
		let i;
		for (i = 0; i < this.props.cart.length; i++) {
			this.props.checkProduct(i, !this.props.checkAll);
		}
		this.props.checkAllProduct(!this.props.checkAll);
	}

	validationCheckList() {
		let itemValid = this.props.cart.filter((item) => item.check === false);
		if (itemValid.length === 0) {
			return true;
		} else {
			return false;
		}
	}

	sendEmail(dataToPost, orderCode) {
		let type;
		let wa;
		let typeTemp = [];
		if (dataToPost.cartCable.length > 0) {
			typeTemp.push("Kabel");
		}
		if (dataToPost.cartUps.length > 0) {
			typeTemp.push("UPS");
		}
		if (dataToPost.cart3M.length > 0) {
			typeTemp.push("3M");
		}
		if (dataToPost.cartSchneider.length > 0) {
			typeTemp.push("Schneider");
		}
		if (dataToPost.cartAccessories.length > 0) {
			typeTemp.push("Accessories");
		}
		if (typeTemp.length >= 3) {
			let last = typeTemp.slice(-1)[0];
			typeTemp[typeTemp.length - 1] = "dan " + last;
			type = typeTemp.join(", ");
		}
		if (typeTemp.length === 2) {
			type = typeTemp[0] + " dan " + typeTemp[1];
		}
		if (typeTemp.length === 1) {
			type = typeTemp[0];
		}

		if (dataToPost.user.whatsapp === 1) {
			wa = "Bisa";
		} else {
			wa = "Tidak";
		}

		const url = "/orders/mail";
		const dataEmail = {
			customer_name: dataToPost.user.name,
			product_type: type,
			customer_email: dataToPost.user.email,
			order_code: orderCode,

			admin_email1: "baguspurnama889@gmail.com", // ----------------- setting email
			admin_email2: "rangga@sentratekmetalindo.com",
			customer_company: dataToPost.user.company,
			customer_phone: dataToPost.user.phone,
			customer_wa: wa,
		};
		AxiosInstance.post(url, dataEmail)
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	render() {
		if (this.state.taskDone === true) {
			return <Redirect to="/order/success" />;
		}
		const baseURL = process.env.REACT_APP_BACKEND_IMAGES;
		return (
			<div>
				<Helmet>
					<title>Distributor Kabel Metal Surabaya, Jual UPS | PT Sentratek Metalindo</title>
					<meta name="description" content="PT Sentratek Metalindo adalah distributor kabel metal di Kota Surabaya. PT Sentratek Metalindo menerima pesanan kabel metal, UPS, dan produk kelistrikan lainnya. Distributor Kabel Surabaya | Beli Kabel Metal Surabaya | Kable Metal | Rods and Conductor | Aluminium Rods | Copper Rods | Aluminium Conductors | Copper Conductors | Steel Strand | Low Voltage Power" />
				</Helmet>

				<Navbar />
				<ModalContact />
				<LoadingOverlay active={this.state.loading} spinner text="Pesanan Anda sedang diproses...">
					<main ref="main">
						<section className="mt-5">
							<Container>
								<Row className="justify-content-center">
									<Col lg="8" className="p-1 mb-3">
										<div className="border rounded shadow p-3">
											<div className="custom-control custom-checkbox mb-1 mt-3">
												<Input className="custom-control-input" id="chooseAll" type="checkbox" checked={this.validationCheckList()} onChange={() => this.onCheckListAll()} />
												<label className="custom-control-label" htmlFor="chooseAll">
													<b>Pilih Semua Produk</b>
												</label>
											</div>
											<div className="w-100 border my-3" />
											{this.props.cart.length === 0 ? (
												<div className="text-center">
													<i>
														Keranjang Anda masih kosong. Mulai <a href="/#categoryProduct">cari produk</a>
													</i>
												</div>
											) : (
												<>
													{Object.values(this.props.cart).map((item, index) => (
														<div className="custom-control custom-checkbox mb-3 p-0" key={index}>
															<Input className="custom-control-input ml-1" id={index} type="checkbox" checked={item.check} onChange={() => this.props.checkProduct(index, !item.check)} />
															<label htmlFor={index} className="w-100 custom-control-label p-3 ">
																<Row className="hover-one  m-0 p-0 ">
																	<Col lg="3">
																		{item.detail.image !== null ? (
																			<Zoom isZoomed={true}>
																				<img className="rounded img-fluid shadow-lg--hover " src={`${baseURL}/${item.detail.image}`} alt="" />
																			</Zoom>
																		) : (
																			<div className="text-center py-5">
																				<i>No Image</i>
																			</div>
																		)}
																	</Col>
																	<Col className="p-1">
																		<a href={`/${item.type}/${item.detail.id}`} className="font-black" tag={Link}>
																			<div>
																				<h6>
																					<b>{item.detail.name}</b>
																				</h6>
																			</div>
																			<div className="text-black mb-1">
																				<Price price={item.detail.price} discount={item.detail.discount} cutOff={item.detail.cutOff} />
																			</div>
																		</a>

																		{item.type === "cable" ? (
																			<div className="mr-2 d-flex align-items-center">
																				<div>Jumlah (m) :</div>
																				<Input value={item.quantity} className="d-inline mx-1 w-25" bsSize="sm" type="number" min={item.detail.minPurchase !== null ? item.detail.minPurchase : "1"} step={item.detail.minPurchase !== null ? item.detail.minPurchase : "1"} placeholder="-" onChange={(e) => this.props.updateQuantity(index, parseInt(e.target.value))} />

																				{item.detail.minPurchase !== null && item.quantity < item.detail.minPurchase ? (
																					<div>
																						<i className="text-red">
																							<b>Minimal order: {item.detail.minPurchase} m</b>
																						</i>
																					</div>
																				) : item.detail.minPurchase !== null && item.quantity % item.detail.minPurchase !== 0 ? (
																					<div>
																						<i className="text-red">
																							<b>Berlaku kelipatan: {item.detail.minPurchase} m</b>
																						</i>
																					</div>
																				) : null}
																			</div>
																		) : (
																			<div className="mr-2 d-flex align-items-center">
																				<div>Jumlah :</div>
																				<Input value={item.quantity} className="d-inline mx-1 w-25" bsSize="sm" type="number" min="1" placeholder="-" onChange={(e) => this.props.updateQuantity(index, parseInt(e.target.value))} />
																			</div>
																		)}

																		<div>
																			<span>Catatan:</span>
																			<Input value={item.noted} placeholder="Tulis catatan disini (Opsional)" rows="2" type="textarea" className="mb-1" style={{ fontSize: "0.7rem" }} onChange={(e) => this.props.updateNoted(index, e.target.value)} />
																		</div>
																	</Col>

																	<div style={{ rigth: "0", position: "absolute", top: "0", width: "100%", padding: "5px" }}>
																		<Button close id="btnClose" onClick={() => this.props.deleteCart(index)} className="mr-3" />
																		<UncontrolledTooltip delay={0} placement="bottom" target="btnClose">
																			Hapus Produk
																		</UncontrolledTooltip>
																	</div>
																</Row>
															</label>
															<div className="w-100 border my-3" />
														</div>
													))}
												</>
											)}
										</div>
									</Col>
									<Col lg="4" className="p-1">
										<Form role="form" onSubmit={(e) => this.handleSubmit(e)}>
											<Row>
												<Col lg="12" className="order-1 mb-3">
													<div className="border rounded shadow p-3">
														<div className="mb-2" style={{ fontSize: "0.7rem" }}>
															<p>
																<b>Isi Biodata Diri</b>
															</p>
															<FormGroup>
																<InputGroup className="mb-3">
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>
																			<i className="fa fa-user" />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input placeholder="Masukan Nama" type="text" value={this.props.user.name} onChange={(e) => this.props.addName(e.target.value)} required />
																</InputGroup>
															</FormGroup>
															<FormGroup>
																<InputGroup className="mb-3">
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>
																			<i className="fa fa-envelope" />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input placeholder="Masukan Email" type="email" value={this.props.user.email} onChange={(e) => this.props.addEmail(e.target.value)} required />
																</InputGroup>
															</FormGroup>
															<FormGroup>
																<InputGroup className="mb-3">
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>
																			<i className="fa fa-user" />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input placeholder="Masukan Nama Company" type="text" value={this.props.user.company} onChange={(e) => this.props.addCompany(e.target.value)} />
																</InputGroup>
															</FormGroup>
															<FormGroup>
																<InputGroup className="">
																	<InputGroupAddon addonType="prepend">
																		<InputGroupText>
																			<i className="fa fa-whatsapp" />
																		</InputGroupText>
																	</InputGroupAddon>
																	<Input placeholder="Masukan No. Handphone/WhatsApp" type="text" value={this.props.user.phone} onChange={(e) => this.props.addPhone(e.target.value)} required />
																</InputGroup>
															</FormGroup>
															<div className="custom-control custom-control-alternative custom-checkbox ml-1 mt--2">
																<input className="custom-control-input" id=" customCheckLogin" type="checkbox" onChange={() => this.props.setWhatsapp(!this.props.user.whatsapp)} checked={this.props.user.whatsapp} />
																<label className="custom-control-label" htmlFor=" customCheckLogin">
																	<i className="text-muted">Saya dapat dihubungi melalui WhatsApp</i>
																</label>
															</div>
														</div>
													</div>
												</Col>
												<div className="w-100" />
												<Col lg="12" className="order-1">
													<div className="border rounded shadow p-3">
														<div className="mb-2" style={{ fontSize: "0.7rem" }}>
															<i>*Procedure Pemesanan:</i>
															<br />

															<i>1. Cek kembali produk pesanan anda</i>
															<br />
															<i>2. Isi biodata Anda</i>
															<br />
															<i>3. Klik Pesan</i>
															<br />
															<i>4. Tunggu konfirmasi dari Tim kami</i>
															<br />
														</div>
														<Button color="success" type="submit" className="btn-lg w-100 p-2">
															Pesan
														</Button>
														{this.state.alertSubmit}
													</div>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							</Container>
						</section>
					</main>
					<Footer />
				</LoadingOverlay>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.user,
		cart: state.cart,
		checkAll: state.checkAll,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addName: (name) => dispatch(addName(name)),
		addEmail: (email) => dispatch(addEmail(email)),
		addCompany: (company) => dispatch(addCompany(company)),
		checkAllProduct: (status) => dispatch(checkAllProduct(status)),
		addPhone: (phone) => dispatch(addPhone(phone)),
		setWhatsapp: (status) => dispatch(setWhatsapp(status)),
		checkProduct: (index, status) => dispatch(checkProduct(index, status)),
		updateQuantity: (index, quantity) => dispatch(updateQuantity(index, quantity)),
		deleteCart: (index) => dispatch(deleteCart(index)),
		updateNoted: (index, note) => dispatch(updateNoted(index, note)),
		clearSession: () => dispatch(clearSession()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailOrder);
