import React from "react";
import Navbar from "../../components/Navbars/Navbar";
import Footer from "../../components/Footers/CardsFooter";
import Product from "../../components/Cards/CardProductUPS";
import { FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import "./../../assets/css/ListProduct.css";
import ModalContact from "../../components/Modals/ModalContact";
import LoadingPic from "../../assets/img/theme/ripple-loading.gif";
import AxiosInstance from "../../services/AxiosService";
import { Helmet } from "react-helmet";
import landingPic from "./../../assets/img/brand/landing2.jpg";
// import "../../assets/scss/Landing.scss";

export default class ListProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listUps: null,
			listShowUps: null,
			filterActive: {
				filter: true,
				populer: false,
				discount: false,
			},
		};
	}

	search(searchField) {
		const list = this.state.listUps;
		if (searchField === "" || searchField === null) {
			this.setState({ listShowUps: this.state.listUps });
		}
		const filteredPersons = list.filter((item) => {
			return item.name.toLowerCase().includes(searchField.toLowerCase());
		});
		this.setState({ listShowUps: filteredPersons });
	}

	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
		this.fetchData();
	}

	fetchData() {
		const url = "/ups";
		AxiosInstance.get(url)
			.then((response) => {
				this.setState({
					listUps: response.data,
					listShowUps: response.data,
				});
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	filter() {
		this.setState({ listUps: null, listShowUps: null });
		const { filter, populer, discount } = this.state.filterActive;
		const url = `/ups?filter=${filter}&isPopular=${populer}&discount=${discount}`;
		AxiosInstance.get(url)
			.then((response) => {
				this.setState({
					listUps: response.data,
					listShowUps: response.data,
				});
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	handleCheck(param) {
		this.setState({
			filterActive: {
				...this.state.filterActive,
				[param]: !this.state.filterActive[param],
			},
		});
	}

	render() {
		const listShowUps = this.state.listShowUps;
		console.log(listShowUps);
		return (
			<div>
				<Helmet>
					<title>Distributor Kabel Metal Surabaya, Jual UPS | PT Sentratek Metalindo</title>
					<meta name="description" content="PT Sentratek Metalindo adalah distributor kabel metal di Kota Surabaya. PT Sentratek Metalindo menerima pesanan kabel metal, UPS, dan produk kelistrikan lainnya. Distributor Kabel Surabaya | Beli Kabel Metal Surabaya | Kable Metal | Rods and Conductor | Aluminium Rods | Copper Rods | Aluminium Conductors | Copper Conductors | Steel Strand | Low Voltage Power" />
				</Helmet>
				<Navbar />

				<ModalContact />
				<main ref="main">
					<div className="picBackgroundProduct">
						<img src={landingPic} alt=""></img>
						<section className="hero-header-text text-center">
							<h1 className="text-white">UPS/APC</h1>
							<h2 className="text-white">By Schneider Electric</h2>
						</section>
					</div>

					<section className="mt-3">
						<div className="container-fluid">
							<Row className="mb-5">
								<Col lg="12">
									<FormGroup className="searchComponent">
										<InputGroup className="input-group-alternative mb-4 ">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="fa fa-search" style={{ color: "black" }} />
												</InputGroupText>
											</InputGroupAddon>
											<Input className="form-control-alternative text-center" placeholder="Cari Produk" type="text" style={{ color: "black" }} id="inputSearch" onChange={(e) => this.search(e.target.value)} />
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col lg="2">
									<div>
										<b>Filter by:</b>
										<div className="custom-control custom-checkbox mb-1 mt-3">
											<Input className="custom-control-input" id="check_populer" type="checkbox" checked={this.state.filterActive.populer} onChange={this.handleCheck.bind(this, "populer")} />
											<label className="custom-control-label" htmlFor="check_populer">
												Produk Populer
											</label>
										</div>
										<div className="custom-control custom-checkbox mb-1">
											<Input className="custom-control-input" id="check_diskon" type="checkbox" checked={this.state.filterActive.discount} onChange={this.handleCheck.bind(this, "discount")} />
											<label className="custom-control-label" htmlFor="check_diskon">
												Produk Diskon
											</label>
										</div>
									</div>
									<div className="text-center reset mt-3">
										<button id="filterBtn " className="btn btn-outline-success btn-sm" onClick={() => this.filter()}>
											Filter
										</button>
									</div>
								</Col>
								<Col lg="10">
									{listShowUps === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<Row className="row-grid p-3 " id="listUps">
											{listShowUps.length > 0 ? (
												Object.values(listShowUps).map((item) => <Product cable={item} key={item.id} />)
											) : (
												<div className="w-100 text-center">
													<i>Produk Tidak ditemukan, coba gunakan kata kunci/filter lainnya</i>
													<br />
													<i>
														Jika anda butuh bantuan,{" "}
														<a href="https://wa.me/6281222227312" target="_blank" rel="noreferrer" className="font-weight-600">
															Hubungi Kami
														</a>
													</i>
												</div>
											)}
										</Row>
									)}
								</Col>
							</Row>
						</div>
					</section>
				</main>
				<Footer />
			</div>
		);
	}
}
