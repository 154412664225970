import React from "react";
import { Button, Modal } from "reactstrap";
import { connect } from "react-redux";
import { showModalContact } from "../../redux/actions/Index";

class Modals extends React.Component {
  render() {
    return (
      <Modal
        className="modal-dialog-centered modal-success"
        contentClassName="bg-gradient-danger"
        isOpen={this.props.modalContact}
        toggle={() => this.props.showModalContact(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="modal-title-notification">
            Hubungi Kami
          </h4>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.props.showModalContact(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-4">
            <span>
              Marketing : <b>0811-328-951</b> (Phone/WhatsApp){" "}
            </span>
            <div className="py-0 text-center">
              <span>Atau</span>

              <a
                className="font-italic font-weight-bold"
                href="http://wa.me/62819827202"
                target="_blank"
                rel="noreferrer"
              >
                <h6>
                  <i className="fa fa-whatsapp fa-lg mr-1" />
                  Hubungi Langsung
                </h6>
              </a>
            </div>
          </div>

          <div className="">
            <span>
              Marketing : <b>081-22222-7312</b> (Phone/WhatsApp){" "}
            </span>
            <div className="py-0 text-center">
              <span>Atau</span>

              <a
                className="font-italic font-weight-bold"
                href="http://wa.me/6281222227312"
                target="_blank"
                rel="noreferrer"
              >
                <h6>
                  <i className="fa fa-whatsapp fa-lg mr-1" />
                  Hubungi Langsung
                </h6>
              </a>
            </div>
          </div>
        </div>
        <div className="modal-footer p-0">
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => this.props.showModalContact(false)}
          >
            Keluar
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalContact: state.modalContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModalContact: (status) => dispatch(showModalContact(status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Modals);
