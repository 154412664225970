import React from "react";

import { Container, Row, Col } from "reactstrap";

class CardsFooter extends React.Component {
	render() {
		return (
			<>
				<footer className="footer has-cards" style={titleFooter}>
					<Container>
						<Row className=" align-items-center my-md text-center">
							<Col lg="12">
								<h3 className="text-primary font-weight-light mb-2">Terima kasih telah berkunjung!</h3>
								<h4 className="mb-0 font-weight-light">Pesan produk kabel dengan mudah pada kami.</h4>
							</Col>
						</Row>
						<hr />
						<Row className="align-items-center justify-content-md-between">
							<Col className="text-center">
								<div className="copyright">
									Copyright © {new Date().getFullYear()} PT. Sentratek Metalindo by <span> </span>
									<a href="http://www.baguspurnama.com" target="_blank" rel="noopener noreferrer">
										Bagus Purnama
									</a>
									.
								</div>
							</Col>
						</Row>
					</Container>
				</footer>
			</>
		);
	}
}

export default CardsFooter;

const titleFooter = {
	clear: "both",
	position: "relative",
	marginTop: "-170px",
};
