import * as actionTypes from "./ActionTypes";

export const showModalContact = (payload) => {
	return {
		type: actionTypes.USER_CHECK_CONTACT,
		status: payload,
	};
};

export const showModalOrder = (status, product) => {
	return {
		type: actionTypes.USER_SHOW_ORDER_MODAL,
		status: status,
		product: product,
	};
};

export const addCableToCart = (product) => {
	return {
		type: actionTypes.USER_ADD_CABLE_TO_CART,
		product: product,
	};
};

export const checkProduct = (index, status) => {
	return {
		type: actionTypes.USER_CHECK_PRODUCT,
		index: index,
		status: status,
	};
};

export const checkAllProduct = (status) => {
	return {
		type: actionTypes.USER_CHECK_ALL,
		status: status,
	};
};
export const updateQuantity = (index, quantity) => {
	return {
		type: actionTypes.USER_UPDATE_QUANTITY,
		index: index,
		quantity: quantity,
	};
};

export const updateNoted = (index, noted) => {
	return {
		type: actionTypes.USER_UPDATE_NOTED,
		index: index,
		noted: noted,
	};
};

export const deleteCart = (index) => {
	return {
		type: actionTypes.USER_DELETE_CART,
		index: index,
	};
};

// ------------------------------------
export const addName = (name) => {
	return {
		type: actionTypes.USER_ADD_NAME,
		name: name,
	};
};

export const addEmail = (email) => {
	return {
		type: actionTypes.USER_ADD_EMAIL,
		email: email,
	};
};

export const addPhone = (phoneNumber) => {
	return {
		type: actionTypes.USER_ADD_PHONE_NUMBER,
		phoneNumber: phoneNumber,
	};
};

export const addCompany = (company) => {
	return {
		type: actionTypes.USER_ADD_COMPANY,
		company: company,
	};
};

export const setWhatsapp = (whatsapp) => {
	return {
		type: actionTypes.USER_SET_WHATSAPP,
		whatsapp: whatsapp,
	};
};

export const clearSession = () => {
	return {
		type: actionTypes.USER_CLEAR_SESSION,
	};
};
