import React from "react";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Modal, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { showModalOrder, addCableToCart } from "../../redux/actions/Index";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import SweetAlert from "react-bootstrap-sweetalert";

class Modals extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			product: {
				detail: null,
				quantity: "",
				noted: "",
				check: true,
				type: "schneider",
			},
			statusError: null,
		};
	}

	async handleTambahOnClick(minPurchase) {
		if (minPurchase !== null) {
			let quantity = this.state.product.quantity === "" ? 0 : this.state.product.quantity;
			// console.log(quantity, parseInt(minPurchase));

			if (parseInt(quantity) < parseInt(minPurchase)) {
				this.handleError(minPurchase);
			} else {
				this.processProduct();
			}
		} else {
			this.processProduct();
		}
	}

	async processProduct() {
		await this.setState({
			product: {
				...this.state.product,
				detail: this.props.modalOrder.product,
				additional_desc: this.props.modalOrder.product.additional_desc,
			},
		});
		await this.props.addCableToCart(this.state.product);
		await this.props.showModalOrder(false, null);
	}

	handleError(minPurchase) {
		const alert = () => (
			<SweetAlert danger title="Gagal!" onConfirm={() => this.setState({ statusError: null })} onCancel={() => this.setState({ statusError: null })}>
				Quantity kurang dari minimal pemesanan ({minPurchase} meter)
			</SweetAlert>
		);

		this.setState({
			statusError: alert(),
		});
	}

	render() {
		const baseURL = process.env.REACT_APP_BACKEND_IMAGES;
		const product = this.props.modalOrder.product;
		// console.log(product);
		return (
			<Modal className="modal-dialog-centered" size="lg" isOpen={this.props.modalOrder.statusShow} toggle={() => this.props.showModalOrder(false, null)}>
				{product === null ? null : (
					<div className="modal-body p-0">
						<Card className="bg-secondary shadow border-0">
							<CardHeader className="bg-transparent pb-2">
								<div className="text-muted text-center mt-2 mb-1">
									<h3>Pemesanan</h3>
								</div>
							</CardHeader>
							<CardBody className="px-lg-5 py-lg-3">
								<Row className="mb-4">
									<Col lg="4">
										<div className="text-center border rounded p-1">
											{product.image !== null ? (
												<Zoom isZoomed={true}>
													<img className="rounded img-fluid shadow-lg--hover" style={{ maxHeight: "180px" }} src={`${baseURL}/${product.image}`} alt="" />
												</Zoom>
											) : (
												<div style={{ marginTop: "50px", marginBottom: "50px" }}>
													<i style={{ fontSize: "0.8rem" }}>Tidak ada foto produk</i>
												</div>
											)}
										</div>
									</Col>
									<Col>
										<Row>
											<Col lg="12">
												<h3>{product.name}</h3>
											</Col>
										</Row>

										<Row className="mb-1">
											<Col className="font-weight-bold">Transmision</Col>
											<Col>{product.transmission}</Col>
										</Row>
										<div className="w-100" />
										<Row className="mb-1">
											<Col className="font-weight-bold">Description</Col>
											<Col>{product.additional_desc}</Col>
										</Row>
									</Col>
								</Row>
								<Form role="form" onSubmit={() => this.handleTambahOnClick(product.minPurchase)}>
									<Row className="mb-2">
										<Col lg="3" style={{ fontSize: "1rem", color: "black" }}>
											<b>Jumlah</b>
										</Col>
										<Col>
											<FormGroup className="mb-1">
												<InputGroup className="input-group-alternative">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="fa fa-cart-plus" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														placeholder="Masukan jumlah produk"
														type="number"
														min="1"
														value={this.state.product.quantity}
														style={{ color: "black" }}
														required
														onChange={(e) =>
															this.setState({
																product: {
																	...this.state.product,
																	quantity: parseInt(e.target.value),
																},
															})
														}
													/>
												</InputGroup>
											</FormGroup>
										</Col>
									</Row>
									<Row className="align-items-start " style={{ fontSize: "1rem", color: "black" }}>
										<Col lg="3">
											<b>Catatan (Opsional)</b>
										</Col>
										<Col style={{ fontSize: "2rem" }}>
											<Input
												id="catatanPembeli"
												placeholder="Tulis catatan disini (Opsional)"
												rows="3"
												type="textarea"
												className="mb-3"
												value={this.state.product.noted}
												onChange={(e) =>
													this.setState({
														product: {
															...this.state.product,
															noted: e.target.value,
														},
													})
												}
											/>
										</Col>
									</Row>
									<div className="text-right">
										<Button className="" color="danger" type="button" onClick={() => this.props.showModalOrder(false, null)}>
											Batal
										</Button>
										<Button className="btn-icon btn-3" color="primary" type="submit">
											<span className="btn-inner--icon">
												<i className="fa fa-plus" />
											</span>
											<span className="btn-inner--text">Tambah</span>
										</Button>
									</div>
								</Form>
							</CardBody>
						</Card>
					</div>
				)}
				{this.state.statusError}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		modalOrder: state.modalOrder,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalOrder: (status, productName) => dispatch(showModalOrder(status, productName)),
		addCableToCart: (product) => dispatch(addCableToCart(product)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Modals);
