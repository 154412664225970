import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbars/Navbar";
import CardsFooter from "../components/Footers/CardsFooter";
import { Button, Card, CardBody, Input, Container, CardHeader, Table, Row, Col, Form } from "reactstrap";

import landingPic from "./../assets/img/brand/landing2.jpg";
import "../assets/scss/Landing.scss";
import { connect } from "react-redux";
import { showModalContact } from "../redux/actions/Index";
import ModalContact from "../components/Modals/ModalContact";
import AxiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";

class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showStatus: false,
			orderCode: "",
			data: null,
		};
	}
	componentDidMount() {
		if (this.props.match.params.kode !== undefined) {
			this.setState({
				showStatus: true,
				orderCode: this.props.match.params.kode,
			});
			this.fetchData(this.props.match.params.kode);
		}
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
	}

	fetchData(orderCode) {
		let code = orderCode.replace(/ /g, "");
		const url = `/ordering/check/${code}`;
		AxiosInstance.get(url)
			.then((response) => {
				setTimeout(() => this.setState({ data: response.data }), 1000);
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	requestData(e, orderCode) {
		e.preventDefault();
		this.setState({ showStatus: true, data: null });
		this.fetchData(orderCode);
	}

	formatRupiah(angka, prefix) {
		var number_string = String(angka)
				.replace(/[^,\d]/g, "")
				.toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		if (ribuan) {
			let separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
		return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
	}

	countDiscount(price, percent) {
		if (percent === null) {
			return price;
		} else {
			let potongan = (parseInt(price) * parseInt(percent)) / 100;
			return parseInt(price) - parseInt(potongan);
		}
	}

	render() {
		const data = this.state.data;
		console.log(data);
		return (
			<div>
				<Helmet>
					<title>Distributor Kabel Metal Surabaya, Jual UPS | PT Sentratek Metalindo</title>
					<meta name="description" content="PT Sentratek Metalindo adalah distributor kabel metal di Kota Surabaya. PT Sentratek Metalindo menerima pesanan kabel metal, UPS, dan produk kelistrikan lainnya. Distributor Kabel Surabaya | Beli Kabel Metal Surabaya | Kable Metal | Rods and Conductor | Aluminium Rods | Copper Rods | Aluminium Conductors | Copper Conductors | Steel Strand | Low Voltage Power" />
				</Helmet>
				<Navbar />
				<ModalContact />
				<main ref="main">
					<section className="section section-lg section-shaped pb-100">
						<div className="shape shape-style-1 " style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
							<img src={landingPic} className="landing-pic" alt=""></img>
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
						<Container className="py-lg-md d-flex">
							<div className="col px-0">
								<Row>
									<Col lg="12" className="text-center textLanding">
										<Form onSubmit={(e) => this.requestData(e, this.state.orderCode)}>
											<h2 className="display-1 text-white">CEK STATUS PESANAN</h2>
											<h5 className="text-white mb-3">Masukan kode pemesanan yang Anda dapatkan pada email Anda</h5>
											<Input type="text" className="mb-3 text-center text-uppercase" placeholder="Tulis kode pemesanan" value={this.state.orderCode} onChange={(e) => this.setState({ orderCode: e.target.value })} required />
											<div className="btn-wrapper">
												<Button className="btn-white btn-icon mb-3 mb-sm-0 ml-1" color="default" type="submit">
													<span className="btn-inner--text">Cek Status Pesanan</span>
													<span className="btn-inner--icon ml-1">
														<i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
													</span>
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</div>
						</Container>
					</section>

					{this.state.showStatus === true ? (
						<section className="section bg-secondary" id="statusPesanan">
							<Container>
								{data === null ? (
									<div className="text-center">
										<img src={LoadingPic} alt="..." />
									</div>
								) : data.dataProduct.cable.length + data.dataProduct.ups.length + data.dataProduct.threeM.length + data.dataProduct.schneider.length !== 0 ? (
									<div>
										<div className="text-center py-3 titleSection">
											<h1 className="title-category">Informasi Pemesanan</h1>
											<h1 className="subtitle-category">Status Pesanan</h1>
										</div>
										<div className="mt-2 mb-3">
											<Row className="font-weight-bold" style={{ fontSize: "1.2rem" }}>
												<Col className="col-2 mb-0 ">
													<span>Nama:</span>
												</Col>
												<Col className="col-9 mb-0 text-capitalize">
													<span>{data.dataCustomer.name}</span>
												</Col>

												<Col className="col-2 mb-0">
													<span>Email:</span>
												</Col>
												<Col className="col-9 mb-0">
													<span>{data.dataCustomer.email}</span>
												</Col>

												<Col className="col-2 mb-0">
													<span>Telp:</span>
												</Col>
												<Col className="col-9 mb-0">
													<span>{data.dataCustomer.phone}</span> <span>{data.dataCustomer.whatsapp === 1 ? "(WA)" : null}</span>
												</Col>

												<Col className="col-2 mb-0">
													<span>Perusahaan:</span>
												</Col>
												<Col className="col-9 mb-0">
													<span>{data.dataCustomer.company}</span>
												</Col>
											</Row>
										</div>
										<div>
											{data.dataProduct.cable.length !== 0 ? (
												<Card className="bg-secondary rounded shadow mb-3">
													<CardHeader className="bg-white border-0">
														<Row className="align-items-center">
															<Col xs="8">
																<h4 className="mb-0 text-uppercase">Daftar Pesanan Kabel</h4>
															</Col>
															<Col className="text-right" xs="4">
																{data.dataOrder.cable.confirm === 1 ? (
																	<Button color="success" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Sedang diproses
																	</Button>
																) : data.dataOrder.cable.confirm === 0 ? (
																	<Button color="danger" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Belum dikonfirmasi
																	</Button>
																) : (
																	<Button color="primary" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Selesai
																	</Button>
																)}
															</Col>
														</Row>
													</CardHeader>
													<CardBody>
														<Table className="align-items-center table-flush" responsive>
															<thead className="thead-light">
																<tr>
																	<th scope="col">Nama Produk</th>
																	<th scope="col">Harga</th>
																	<th scope="col">Jumlah</th>
																	<th scope="col">Catatan</th>
																</tr>
															</thead>

															<tbody>
																{Object.values(data.dataProduct.cable).map((item) => (
																	<tr key={item.id}>
																		<td>
																			<a href={`/cable/${item.product_id}`}>{item.product_name}</a>
																		</td>
																		<td>{item.price !== null ? <div>{this.formatRupiah(this.countDiscount(item.price, item.discount), "Rp. ")}</div> : "-"}</td>

																		<td>{item.quantity} Meter</td>

																		<td style={{ maxWidth: "150px" }}>{item.noted !== null ? item.noted : "-"}</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</CardBody>
												</Card>
											) : null}
										</div>

										{/* ----------------- */}
										<div>
											{data.dataProduct.ups.length !== 0 ? (
												<Card className="bg-secondary rounded shadow mb-3">
													<CardHeader className="bg-white border-0">
														<Row className="align-items-center">
															<Col xs="8">
																<h4 className="mb-0 text-uppercase">Daftar Pesanan UPS</h4>
															</Col>
															<Col className="text-right" xs="4">
																{data.dataOrder.ups.confirm === 1 ? (
																	<Button color="success" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Sedang diproses
																	</Button>
																) : data.dataOrder.ups.confirm === 0 ? (
																	<Button color="danger" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Belum dikonfirmasi
																	</Button>
																) : (
																	<Button color="primary" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Selesai
																	</Button>
																)}
															</Col>
														</Row>
													</CardHeader>
													<CardBody>
														<Table className="align-items-center table-flush" responsive>
															<thead className="thead-light">
																<tr>
																	<th scope="col">Nama Produk</th>
																	<th scope="col">Harga</th>
																	<th scope="col">Jumlah</th>
																	<th scope="col">Catatan</th>
																</tr>
															</thead>

															<tbody>
																{Object.values(data.dataProduct.ups).map((item) => (
																	<tr key={item.id}>
																		<td>
																			<a href={`/ups/${item.product_id}`}>{item.product_name}</a>
																		</td>
																		<td>{item.price == null ? "-" : this.formatRupiah(item.price, "Rp. ")}</td>

																		<td>{item.quantity}</td>

																		<td style={{ maxWidth: "150px" }}>{item.noted !== null ? item.noted : <i>tidak ada catatan khusus</i>}</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</CardBody>
												</Card>
											) : null}
										</div>

										{/* ----------------- */}
										<div>
											{data.dataProduct.threeM.length !== 0 ? (
												<Card className="bg-secondary shadow mb-3">
													<CardHeader className="bg-white border-0">
														<Row className="align-items-center">
															<Col xs="8">
																<h4 className="mb-0 text-uppercase">Daftar Pesanan 3M</h4>
															</Col>
															<Col className="text-right" xs="4">
																{data.dataOrder.threeM.confirm === 1 ? (
																	<Button color="success" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Sedang diproses
																	</Button>
																) : data.dataOrder.threeM.confirm === 0 ? (
																	<Button color="danger" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Belum dikonfirmasi
																	</Button>
																) : (
																	<Button color="primary" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Selesai
																	</Button>
																)}
															</Col>
														</Row>
													</CardHeader>
													<CardBody>
														<Table className="align-items-center table-flush" responsive>
															<thead className="thead-light">
																<tr>
																	<th scope="col">Nama Produk</th>
																	<th scope="col">Harga</th>
																	<th scope="col">Jumlah</th>
																	<th scope="col">Catatan</th>
																</tr>
															</thead>

															<tbody>
																{Object.values(data.dataProduct.threeM).map((item) => (
																	<tr key={item.id}>
																		<td>
																			<a href={`/3m/${item.product_id}`}>{item.product_name}</a>
																		</td>
																		<td>{item.price == null ? "-" : this.formatRupiah(item.price, "Rp. ")}</td>

																		<td>{item.quantity}</td>

																		<td style={{ maxWidth: "150px" }}>{item.noted !== null ? item.noted : <i>tidak ada catatan khusus</i>}</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</CardBody>
												</Card>
											) : null}
										</div>

										{/* ----------------- */}
										<div>
											{data.dataProduct.schneider.length !== 0 ? (
												<Card className="bg-secondary rounded shadow mb-3">
													<CardHeader className="bg-white border-0">
														<Row className="align-items-center">
															<Col xs="8">
																<h4 className="mb-0 text-uppercase">Daftar Pesanan Schneider</h4>
															</Col>
															<Col className="text-right" xs="4">
																{data.dataOrder.schneider.confirm === 1 ? (
																	<Button color="success" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Sedang diproses
																	</Button>
																) : data.dataOrder.schneider.confirm === 0 ? (
																	<Button color="danger" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Belum dikonfirmasi
																	</Button>
																) : (
																	<Button color="primary" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Selesai
																	</Button>
																)}
															</Col>
														</Row>
													</CardHeader>
													<CardBody>
														<Table className="align-items-center table-flush" responsive>
															<thead className="thead-light">
																<tr>
																	<th scope="col">Nama Produk</th>
																	<th scope="col">Harga</th>
																	<th scope="col">Jumlah</th>
																	<th scope="col">Catatan</th>
																</tr>
															</thead>

															<tbody>
																{Object.values(data.dataProduct.schneider).map((item) => (
																	<tr key={item.id}>
																		<td>
																			<a href={`/schneider/${item.product_id}`}>{item.product_name}</a>
																		</td>
																		<td>{item.price == null ? "-" : this.formatRupiah(item.price, "Rp. ")}</td>

																		<td>{item.quantity}</td>

																		<td style={{ maxWidth: "150px" }}>{item.noted !== null ? item.noted : <i>tidak ada catatan khusus</i>}</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</CardBody>
												</Card>
											) : null}
										</div>
										{/* ----------------- */}
										<div>
											{data.dataProduct.accessories.length !== 0 ? (
												<Card className="bg-secondary shadow mb-3">
													<CardHeader className="bg-white border-0">
														<Row className="align-items-center">
															<Col xs="8">
																<h4 className="mb-0 text-uppercase">Daftar Pesanan Aksesories</h4>
															</Col>
															<Col className="text-right" xs="4">
																{data.dataOrder.accessories.confirm === 1 ? (
																	<Button color="success" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Sedang diproses
																	</Button>
																) : data.dataOrder.accessories.confirm === 0 ? (
																	<Button color="danger" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Belum dikonfirmasi
																	</Button>
																) : (
																	<Button color="primary" href="#" onClick={(e) => e.preventDefault()} size="sm">
																		Selesai
																	</Button>
																)}
															</Col>
														</Row>
													</CardHeader>
													<CardBody>
														<Table className="align-items-center table-flush" responsive>
															<thead className="thead-light">
																<tr>
																	<th scope="col">Nama Produk</th>
																	<th scope="col">Harga</th>
																	<th scope="col">Jumlah</th>
																	<th scope="col">Catatan</th>
																</tr>
															</thead>

															<tbody>
																{Object.values(data.dataProduct.accessories).map((item) => (
																	<tr key={item.id}>
																		<td>
																			<a href={`/accessories/${item.product_id}`}>{item.product_name}</a>
																		</td>
																		<td>{item.price == null ? "-" : this.formatRupiah(item.price, "Rp. ")}</td>

																		<td>{item.quantity}</td>

																		<td style={{ maxWidth: "150px" }}>{item.noted !== null ? item.noted : <i>tidak ada catatan khusus</i>}</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</CardBody>
												</Card>
											) : null}
										</div>
										<div className="text-center w-full">
											<i>
												Ada pertanyaan?{" "}
												<a href="http://wa.me/6281222227312" target="_blank" rel="noreferrer">
													Hubungi Kami
												</a>
											</i>
										</div>
									</div>
								) : (
									<div className="text-center">
										<b>Maaf, Kode Pesanan Tidak Ditemukan</b>
										<br />
										<i>Silahkan coba masukan kembali kode pesanan Anda yang Anda dapatkan pada email Anda, atau segera hubungi kami.</i>
									</div>
								)}
							</Container>
						</section>
					) : null}
				</main>
				<CardsFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		modalContact: state.modalContact,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalContact: (status) => dispatch(showModalContact(status)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(About);
