export const USER_CHECK_CONTACT = "USER_CHECK_CONTACT";
export const USER_SHOW_ORDER_MODAL = "USER_SHOW_ORDER_MODAL";
export const USER_ADD_CABLE_TO_CART = "USER_ADD_CABLE_TO_CART";

export const USER_ADD_NAME = "USER_ADD_NAME";
export const USER_ADD_COMPANY = "USER_ADD_COMPANY";
export const USER_ADD_EMAIL = "USER_ADD_EMAIL";
export const USER_ADD_PHONE_NUMBER = "USER_ADD_PHONE_NUMBER";
export const USER_SET_WHATSAPP = "USER_SET_WHATSAPP";

export const USER_CHECK_ALL = "USER_CHECK_ALL";
export const USER_CHECK_PRODUCT = "USER_CHECK_PRODUCT";
export const USER_UPDATE_QUANTITY = "USER_UPDATE_QUANTITY";
export const USER_UPDATE_NOTED = "USER_UPDATE_NOTED";
export const USER_DELETE_CART = "USER_DELETE_CART";

export const USER_CLEAR_SESSION = "USER_CLEAR_SESSION";
