import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Badge } from "reactstrap";
import "react-medium-image-zoom/dist/styles.css";
import fireIcon from "../../assets/img/icons/fire.svg";
import "../../assets/scss/Card.scss";
import Price from "../Price";

class CardProduct extends React.Component {
	render() {
		const baseURL = process.env.REACT_APP_BACKEND_IMAGES;
		const threeM = this.props.threeM;
		return (
			<div className="mb-3 colProductList-long">
				<Card className="rounded shadow border-0  cardProduct card__one">
					<a href={`/3m/${threeM.id}`} tag={Link}>
						{threeM.isPopular === 1 ? (
							<span className="notify-badge">
								<div className=" icon-shape text-white p-1 popularIcon img-hover-zoom">
									<img src={fireIcon} className="img-fluid icon-notify" alt=""></img>
								</div>
							</span>
						) : null}

						{threeM.image !== null ? (
							<div className="previewProduct">
								<img alt="" src={`${baseURL}/${threeM.image}`} className="" />
							</div>
						) : (
							<div className="previewProduct d-flex align-items-center justify-content-center">
								<i>Tidak ada foto produk</i>
							</div>
						)}

						<CardBody className="py-1 px-2 ">
							<div style={titleStyle} className="font-weight-600 crop-text-2 ">
								{threeM.name}
							</div>
							<Price price={threeM.price} discount={threeM.discount} cutOff={threeM.cutOff} />
							<div>
								{threeM.conductor_range !== null ? (
									<Badge color="danger" pill className="shadow p-1 mr-1 badge-features">
										Conductor R. : {threeM.conductor_range}
									</Badge>
								) : null}
								{threeM.conductor_range !== null ? (
									<Badge color="danger" pill className=" p-1 mr-1 badge-features">
										Voltage R. : {threeM.voltage_range}
									</Badge>
								) : null}
								{/* {threeM.isPopular !== 0 ? (
									<Badge color="warning" pill className="p-1 mr-1" style={{ fontSize: "0.6rem" }}>
										
										Populer
									</Badge>
								) : null} */}
							</div>
						</CardBody>
					</a>
				</Card>
			</div>
		);
	}
}

export default CardProduct;

const titleStyle = {
	fontSize: "0.9rem",
};
