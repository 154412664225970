import React from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/HashLoader";
// import { useLocation } from "react-router-dom";
import AxiosInstance from "../services/AxiosService";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

export default class AwesomeComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
	}

	componentDidMount() {
		let codeTrack = this.props.match.params.code;
		let destination = this.props.match.params.destination;
		let url_desti = destination.replace("%2f", "/").replace("-", "#");

		console.log(url_desti);
		const url = `/tracking/track/${codeTrack}`;
		console.log(url);

		AxiosInstance.get(url)
			.then((response) => {
				if (response.data == "Success") {
					setTimeout(
						function () {
							window.location.replace(url_desti);
						}.bind(this),
						2000
					);
				}
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	render() {
		return (
			<div className="sweet-loading h-100vh flex justify-content-center align-items-center">
				<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
					<ClipLoader css={override} size={150} color={"maroon"} loading={this.state.loading} />
				</div>
			</div>
		);
	}
}
