import * as actionType from "../actions/ActionTypes";
import update from "react-addons-update";

const initialState = {
	modalContact: false,
	modalOrder: {
		statusShow: false,
		product: null,
	},
	user: {
		name: "",
		email: "",
		phone: "",
		company: "",
		whatsapp: false,
	},
	cart: [],
	checkAll: true,
};

const UserReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionType.USER_CHECK_ALL:
			return update(state, {
				checkAll: { $set: action.status },
			});

		case actionType.USER_CHECK_CONTACT:
			return update(state, {
				modalContact: { $set: action.status },
			});

		case actionType.USER_SHOW_ORDER_MODAL:
			return update(state, {
				modalOrder: {
					statusShow: { $set: action.status },
					product: { $set: action.product },
				},
			});

		case actionType.USER_ADD_CABLE_TO_CART:
			return update(state, {
				cart: {
					$push: [action.product],
				},
			});

		case actionType.USER_ADD_NAME:
			return update(state, {
				user: {
					name: { $set: action.name },
				},
			});
		case actionType.USER_ADD_EMAIL:
			return update(state, {
				user: {
					email: { $set: action.email },
				},
			});
		case actionType.USER_ADD_COMPANY:
			return update(state, {
				user: {
					company: { $set: action.company },
				},
			});

		case actionType.USER_ADD_PHONE_NUMBER:
			return update(state, {
				user: {
					phone: { $set: action.phoneNumber },
				},
			});

		case actionType.USER_SET_WHATSAPP:
			return update(state, {
				user: {
					whatsapp: { $set: action.whatsapp },
				},
			});

		case actionType.USER_CHECK_PRODUCT:
			return update(state, {
				cart: {
					[action.index]: {
						check: { $set: action.status },
					},
				},
			});

		case actionType.USER_UPDATE_QUANTITY:
			return update(state, {
				cart: {
					[action.index]: {
						quantity: { $set: action.quantity },
					},
				},
			});

		case actionType.USER_UPDATE_NOTED:
			return update(state, {
				cart: {
					[action.index]: {
						noted: { $set: action.noted },
					},
				},
			});

		case actionType.USER_DELETE_CART:
			return update(state, {
				cart: { $splice: [[action.index, 1]] },
			});

		case actionType.USER_CLEAR_SESSION:
			return {
				...state,
				modalContact: false,
				modalOrder: {
					statusShow: false,
					product: null,
				},
				user: {
					name: "",
					email: "",
					phone: "",
					whatsapp: false,
				},
				cart: [],
			};
		default:
			return state;
	}
};

export default UserReducer;
