import React, { Component } from "react";
import Navbar from "../../components/Navbars/Navbar";
import Footer from "../../components/Footers/CardsFooter";
import { Button, UncontrolledTooltip, Container, Row, Col } from "reactstrap";
import "./../../assets/css/ListProduct.css";
import ModalContact from "../../components/Modals/ModalContact";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { connect } from "react-redux";
import { showModalOrder } from "../../redux/actions/Index";
import ModalOrder from "../../components/Modals/ModalOrderUps";
import { Helmet } from "react-helmet";
import AxiosInstance from "../../services/AxiosService";
import LoadingPic from "../../assets/img/theme/ripple-loading.gif";
import Price from "../../components/Price";

class ListProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ups: null,
		};
	}
	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
		this.fetchData();
	}

	fetchData() {
		const url = `/ups/${this.props.match.params.idProduct}`;
		AxiosInstance.get(url)
			.then((response) => {
				this.setState({
					ups: response.data,
				});
				// console.log(response.data);
			})
			.catch((error) => {
				if (error.response.status === 404) {
					this.props.history.replace("/notfound");
				} else {
					alert(error.message);
				}
			});
	}

	render() {
		const baseURL = process.env.REACT_APP_BACKEND_IMAGES;
		const baseURLDoc = process.env.REACT_APP_BACKEND_DOCUMENTS;
		const ups = this.state.ups;

		return (
			<div>
				<Helmet>
					<title>Distributor Kabel Metal Surabaya, Jual UPS | PT Sentratek Metalindo</title>
					<meta name="description" content="PT Sentratek Metalindo adalah distributor kabel metal di Kota Surabaya. PT Sentratek Metalindo menerima pesanan kabel metal, UPS, dan produk kelistrikan lainnya. Distributor Kabel Surabaya | Beli Kabel Metal Surabaya | Kable Metal | Rods and Conductor | Aluminium Rods | Copper Rods | Aluminium Conductors | Copper Conductors | Steel Strand | Low Voltage Power" />
				</Helmet>
				<Navbar />
				<ModalContact />

				<main ref="main">
					{ups === null ? (
						<div className="text-center">
							<img src={LoadingPic} alt="..." />
						</div>
					) : (
						<section className="mt-5">
							<Container>
								<Row>
									<Col lg="5" className="order-md-1 text-center mb-5">
										<div className="border rounded p-3 d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
											{ups.image !== null ? (
												<Zoom isZoomed={true}>
													<img className="rounded img-fluid" style={{ maxHeight: "300px" }} src={`${baseURL}/${ups.image}`} alt="" />
												</Zoom>
											) : (
												<div className="text-center">
													<i>Tidak ada foto produk</i>
												</div>
											)}
										</div>
									</Col>
									<Col lg="7" className="order-md-2 mb-5 ">
										<div className="rounded border p-4" style={{ height: "100%" }}>
											<div className="heading-information">
												<h3 className="font-weight-bold">{ups.name}</h3>
											</div>
											<div className="mb-3">
												<Row className="mb-1">
													<Col lg="4" xs="6" className="font-weight-bold">
														Harga
													</Col>

													<Col>
														<Price price={ups.price} discount={ups.discount} cutOff={ups.cutOff} />
													</Col>
												</Row>
												<Row className="mb-1">
													<Col lg="4" xs="6" className="font-weight-bold">
														Sub Category
													</Col>
													<Col>{ups.subCategory}</Col>
												</Row>
												<div className="w-100" />
												<Row className="mb-1">
													<Col lg="4" xs="6" className="font-weight-bold">
														Power Output
													</Col>
													<Col>{ups.powerOutput}</Col>
												</Row>
												<div className="w-100" />
												<Row className="mb-1">
													<Col lg="4" xs="6" className="font-weight-bold">
														Backup Time
													</Col>
													{ups.backupTime === null ? (
														<Col>
															<i>(By Request)</i>
														</Col>
													) : (
														<Col>{ups.backupTime} Menit</Col>
													)}
												</Row>
												<div className="w-100" />
												<Row className="mb-1">
													<Col lg="4" xs="6" className="font-weight-bold">
														Load
													</Col>
													{ups.runtime === null ? (
														<Col>
															<i>(By Request)</i>
														</Col>
													) : (
														<Col>{ups.runtime}% Full</Col>
													)}
												</Row>
												<div className="w-100" />
												<Row className="mb-1">
													<Col lg="4" xs="6" className="font-weight-bold">
														Referensi
													</Col>
													<Col>{ups.referensi}</Col>
												</Row>
												<div className="w-100" />
											</div>

											<Button color="success" type="button" className="btn-lg w-100 p-2" id="tooltip860969338" onClick={() => this.props.showModalOrder(true, ups)}>
												Pesan Sekarang
											</Button>
											<ModalOrder />
											<UncontrolledTooltip delay={0} placement="bottom" target="tooltip860969338">
												Tekan untuk melakukan pemesanan
											</UncontrolledTooltip>
										</div>
									</Col>
								</Row>
								<div className="w-100"></div>
								<Row className="p-3">
									<Col lg="12" className="border rounded p-3">
										<div>
											<Row className="border-bottom">
												<Col lg="6">
													<h4 className="font-weight-bold">Deskripsi Produk</h4>
												</Col>

												{ups.desc !== null ? (
													<Col lg="6 text-right">
														<div className="small">
															<i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
															<i>
																Jika deskripsi produk tidak keluar{" "}
																<a href={`${baseURLDoc}/${ups.desc}`} target="_blank" rel="noreferrer">
																	<b>klik disini</b>
																</a>
															</i>
														</div>
													</Col>
												) : null}
											</Row>
										</div>
										<div className="previewPDF">{ups.desc === null ? <div className="text-center font-weight-600 font-italic">Tidak terdapat dokumen pendukung</div> : <embed src={`${baseURLDoc}/${ups.desc}`} width="100%" height="100%" />}</div>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				</main>
				<Footer />
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		modalOrder: state.modalOrder,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalOrder: (status, product) => dispatch(showModalOrder(status, product)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ListProduct);
