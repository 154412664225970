import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbars/Navbar";
import CardsFooter from "../components/Footers/CardsFooter";
import { Button } from "reactstrap";
import "../assets/scss/Landing.scss";
import { connect } from "react-redux";
import { showModalContact, clearSession } from "../redux/actions/Index";
import ModalContact from "../components/Modals/ModalContact";

class MessageSuccess extends React.Component {
	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
		this.props.clearSession();
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>PT Sentratek Metalindo</title>
					<meta
						name="description"
						content="PT Sentratek Metalindo adalah distributor kabel metal yang berada di Kota Surabaya. PT Sentratek Metalindo menerima pemesanan kabel metal, UPS, dan produk kelistrikan lainnya.  · Distributor Kabel Surabaya · Beli Kabel Metal Surabaya · Kable Metal · Rods and Conductor · Aluminium Rods · Copper Rods · Aluminium Conductors · Copper Conductors · Steel Strand · Low Voltage Power "
					/>
				</Helmet>
				<Navbar />
				<ModalContact />
				<main ref="main">
					<section className="section section-lg section-shaped pb-200">
						<div className="text-center py-4 titleSection">
							<h1 className="title-category">Terima kasih</h1>
							<h4 className="title-category">
								Pesanan Anda sedang kami proses, tunggu tim kami menghubungi
								Anda
							</h4>
							<Button
								href="/"
								className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
								color="default"
							>
								<span className="btn-inner--icon ml-1">
									<i
										className="fa fa-arrow-circle-o-left"
										aria-hidden="true"
									></i>
								</span>
								<span className="btn-inner--text">Kembali ke Beranda</span>
							</Button>
						</div>
					</section>
				</main>
				<CardsFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		modalContact: state.modalContact,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalContact: (status) => dispatch(showModalContact(status)),
		clearSession: () => dispatch(clearSession()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageSuccess);
