import React from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import Navbar from "../components/Navbars/Navbar";
import CardsFooter from "../components/Footers/CardsFooter";
import ListItem from "../components/Cards/ItemIdustry";
import { Button, Card, CardBody, CardImg, FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";

import landingPic from "./../assets/img/brand/landing2.jpg";
import "../assets/scss/Landing.scss";
import companyPic1 from "./../assets/img/company/p4.jpg";
import { connect } from "react-redux";
import { showModalContact } from "../redux/actions/Index";
import ModalContact from "../components/Modals/ModalContact";

class About extends React.Component {
	state = {};
	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Distributor Kabel Metal Surabaya, Jual UPS | PT Sentratek Metalindo</title>
					<meta name="description" content="PT Sentratek Metalindo adalah distributor kabel metal di Kota Surabaya. PT Sentratek Metalindo menerima pesanan kabel metal, UPS, dan produk kelistrikan lainnya. Distributor Kabel Surabaya | Beli Kabel Metal Surabaya | Kable Metal | Rods and Conductor | Aluminium Rods | Copper Rods | Aluminium Conductors | Copper Conductors | Steel Strand | Low Voltage Power" />
					<meta name="generator" content="kabel metal indonesia, sentratek metalindo, distributor kabel metal indonesia, pt sentratek metalindo" />
				</Helmet>
				<Navbar />
				<ModalContact />
				<main ref="main">
					<section className="section section-lg section-shaped pb-250">
						<div className="shape shape-style-1 " style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
							<img src={landingPic} className="landing-pic" alt=""></img>
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
						<Container className="py-lg-md d-flex">
							<div className="col px-0">
								<Row>
									<Col lg="12" className="text-center textLanding">
										<h2 className="display-1 text-white">
											Selamat datang <br />
											di PT. Sentratek Metalindo Surabaya
										</h2>
										<h5 className="text-white mb-5">Kami hadir disini untuk membantu anda menemukan produk kabel sesuai kebutuhan Anda. Anda akan lebih mudah memilih produk dengan website ini. Lakukan pemesanan dan Anda akan kami hubungi.</h5>
										<div className="btn-wrapper">
											<Button className="btn-white btn-icon mb-3 mb-sm-0 ml-1" color="default" href="/#categoryProduct">
												<span className="btn-inner--text">Pesan Sekarang</span>
												<span className="btn-inner--icon ml-1">
													<i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>
												</span>
											</Button>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</section>

					<section className="section bg-secondary" id="profileCompany">
						<Container>
							<div className="text-center py-5 titleSection">
								<h1 className="title-category">Tentang Kami</h1>
								<h1 className="subtitle-category">PT. Sentratek Metalindo</h1>
							</div>
							<Row className="row-grid align-items-center">
								<Col md="6">
									<Card className="bg-default shadow border-0">
										<CardImg alt="..." src={companyPic1} top />
										<blockquote className="card-blockquote">
											<svg xmlns="http://www.w3.org/2000/svg" className="svg-bg" preserveAspectRatio="none" viewBox="0 0 583 95">
												<polygon className="fill-default" points="0,52 583,95 0,95" />
												<polygon className="fill-default" opacity=".2" points="0,42 583,95 683,0 0,95" />
											</svg>
											<h4 className="display-3 font-weight-bold text-white text-center">PT. Sentratek Metalindo</h4>
											<p className="lead text-italic text-white">Sejak berdiri tanggal 30 Juni 1993, PT.Sentratek Metalindo terus hadir untuk turut serta dalam memajukan usaha dalam bidang kelistrikan di Indonesia.</p>
										</blockquote>
									</Card>
								</Col>
								<Col md="6">
									<div className="pl-md-5">
										<div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-3">
											<i className="ni ni-spaceship" />
										</div>
										<h3>Industri Kami</h3>
										<ul className="list-unstyled mt-3">
											<ListItem item="Oil and Gas Industries" />
											<ListItem item="Chemical Industries" />
											<ListItem item="Petrochemical Industries" />
											<ListItem item="Gases Industries" />
											<ListItem item="Sugar mill" />
											<ListItem item="Pulp and Paper Industries" />
											<ListItem item="Cement Industries" />
											<ListItem item="Power Plants" />
											<ListItem item="Water Treatment Plant" />
											<ListItem item="Electrical Utilities" />
											<ListItem item="Buildings" />
											<ListItem item="Etc." />
										</ul>
									</div>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="section section-lg bg-gradient-default ">
						<Container className="pt-md pb-300">
							<Row className="text-center justify-content-center">
								<Col lg="10">
									<h2 className="display-3 text-white">Production Process</h2>
								</Col>
							</Row>
							<Row className="row-grid mt-5">
								<Col lg="4">
									<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
										<i className="ni ni-check-bold text-primary" />
									</div>
									<h5 className="text-white mt-3">Standarisasi</h5>
									<p className="text-white mt-3 text-justify">Produk kami telah memenuhi berbagai macam standart Indonesia maupun internasional</p>
								</Col>
								<Col lg="4">
									<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
										<i className="ni ni-like-2 text-primary" />
									</div>
									<h5 className="text-white mt-3">Jaminana Kualitas</h5>
									<p className="text-white mt-3 text-justify">Produk kami telah melewati tahapan-tahapan quality control dari pengujian di lab, proses, inspeksi setengah jadi, sampai pengujian akhir oleh tenaga ahli dan peralatan canggih</p>
								</Col>
								<Col lg="4">
									<div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
										<i className="ni ni-trophy text-primary" />
									</div>
									<h5 className="text-white mt-3">Produk Spesial</h5>
									<p className="text-white mt-3 text-justify">Kami juga menerima permintaan kabel yang sesuai dengan spesifikasi yang diinginkan</p>
								</Col>
							</Row>
						</Container>
						{/* SVG separator */}
						<div className="separator separator-bottom separator-skew zindex-100">
							<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
								<polygon className="fill-white" points="2560 0 2560 100 0 100" />
							</svg>
						</div>
					</section>

					<section className="section section-lg pt-lg-0 pb-lg-0 section-contact-us">
						<Container>
							<Row className="justify-content-center mt--300">
								<Col lg="8">
									<Card className="bg-gradient-secondary shadow">
										<CardBody className="p-lg-5">
											<h4 className="mb-1">Beri Kami Feedback</h4>
											<p className="mt-0">Karena masukan Anda penting untuk Kami.</p>
											<FormGroup
												className={classnames("mt-5", {
													focused: this.state.nameFocused,
												})}
											>
												<InputGroup className="input-group-alternative">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ni ni-user-run" />
														</InputGroupText>
													</InputGroupAddon>
													<Input placeholder="Nama Anda" type="text" onFocus={(e) => this.setState({ nameFocused: true })} onBlur={(e) => this.setState({ nameFocused: false })} />
												</InputGroup>
											</FormGroup>
											<FormGroup
												className={classnames({
													focused: this.state.emailFocused,
												})}
											>
												<InputGroup className="input-group-alternative">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ni ni-email-83" />
														</InputGroupText>
													</InputGroupAddon>
													<Input placeholder="Alamat Email Anda" type="email" onFocus={(e) => this.setState({ emailFocused: true })} onBlur={(e) => this.setState({ emailFocused: false })} />
												</InputGroup>
											</FormGroup>
											<FormGroup className="mb-4">
												<Input className="form-control-alternative" cols="80" name="name" placeholder="Tulis pesan ..." rows="4" type="textarea" />
											</FormGroup>
											<div>
												<Button block className="btn-round" color="default" size="lg" type="button">
													Kirim Feedback
												</Button>
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					</section>
				</main>
				<CardsFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		modalContact: state.modalContact,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalContact: (status) => dispatch(showModalContact(status)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(About);
