import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbars/Navbar";
import CardsFooter from "../components/Footers/CardsFooter";
import { Container } from "reactstrap";
import "../assets/scss/Landing.scss";
import pic404 from "./../assets/img/icons/404.svg";
import { connect } from "react-redux";
import { showModalContact } from "../redux/actions/Index";
import ModalContact from "../components/Modals/ModalContact";

class About extends React.Component {
	state = {};
	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.main.scrollTop = 0;
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>Distributor Kabel Listrik Surabaya, Jual UPS - PT Sentratek Metalindo </title>
					<meta name="description" content="PT Sentratek Metalindo adalah distributor kabel metal yang berada di Kota Surabaya. PT Sentratek Metalindo menerima pemesanan kabel metal, UPS, dan produk kelistrikan lainnya.  · Distributor Kabel Surabaya · Beli Kabel Metal Surabaya · Kable Metal · Rods and Conductor · Aluminium Rods · Copper Rods · Aluminium Conductors · Copper Conductors · Steel Strand · Low Voltage Power " />
				</Helmet>
				<Navbar />
				<ModalContact />
				<main ref="main">
					<section className="section section-lg mt--100" id="howToOrder">
						<Container>
							<div className="text-center py-4 titleSection">
								<h1 className="title-category">MAAF</h1>
								<h4 className="title-category">Halaman yang anda cari tidak ditemukan</h4>
							</div>
							<div className="text-center">
								<img alt="..." className="img-fluid floating" src={pic404} />
							</div>
						</Container>
					</section>
				</main>
				<CardsFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		modalContact: state.modalContact,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalContact: (status) => dispatch(showModalContact(status)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(About);
