import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Badge } from "reactstrap";
import "react-medium-image-zoom/dist/styles.css";
import fireIcon from "../../assets/img/icons/fire.svg";
import "../../assets/scss/Card.scss";
import Price from "../Price";

class CardProduct extends React.Component {
	render() {
		const baseURL = process.env.REACT_APP_BACKEND_IMAGES;
		const product = this.props.product;
		return (
			<div className="mb-3 colProductList">
				<Card className="rounded shadow border-0  cardProduct card__one">
					<a href={`/accessories/${product.id}`} tag={Link}>
						{product.isPopular === 1 ? (
							<span className="notify-badge">
								<div className=" icon-shape text-white p-1 popularIcon img-hover-zoom">
									<img src={fireIcon} className="img-fluid icon-notify" alt=""></img>
								</div>
							</span>
						) : null}

						{product.image !== null ? (
							<div className="previewProduct">
								<img alt="" src={`${baseURL}/${product.image}`} className="" />
							</div>
						) : (
							<div className="previewProduct d-flex align-items-center justify-content-center">
								<i>Tidak ada foto produk</i>
							</div>
						)}

						<CardBody className="py-1 px-2 ">
							<div style={titleStyle} className="font-weight-600 crop-text-2">
								{product.name}
							</div>
							<div style={titleStyle} className="font-weight-600 ">
								{product.subCategory}
							</div>
							<Price price={product.price} discount={product.discount} cutOff={product.cutOff} />
						</CardBody>
					</a>
				</Card>
			</div>
		);
	}
}

export default CardProduct;

const titleStyle = {
	fontSize: "0.9rem",
};
