import React from "react";
import { Card, CardBody } from "reactstrap";
import "react-medium-image-zoom/dist/styles.css";
import "../../assets/scss/Card.scss";
import { Link } from "react-router-dom";

class CardProduct extends React.Component {
	render() {
		return (
			<Card className="card__one shadow border-0 p-3 text-center cardCategory ">
				<a tag={Link} href={this.props.link}>
					<div className="previewCategory d-flex align-items-center">
						<img alt="..." src={this.props.img} className=" responsive-img " />
					</div>

					<CardBody className="p-0">
						<h4 className="">
							<b>{this.props.nama}</b>
						</h4>
					</CardBody>
				</a>
			</Card>
		);
	}
}

export default CardProduct;
