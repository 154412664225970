import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "./reducers/Index";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { loadState, saveState } from "./SessionStorage";

const persistedState = loadState();
const store = createStore(reducer, persistedState, composeWithDevTools(applyMiddleware(thunk, logger)));

store.subscribe(() => {
	saveState(store);
});
export default store;
