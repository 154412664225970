import React from "react";
import "../assets/scss/Card.scss";
import "animate.css";
import { UncontrolledTooltip } from "reactstrap";

export default class DetailOrder extends React.Component {
	countDiscount(price, percent) {
		if (percent === 0) {
			return price;
		} else {
			let potongan = (parseInt(price) * parseInt(percent)) / 100;
			return parseInt(price) - parseInt(potongan);
		}
	}

	countCutOff(price, cutOff) {
		if (cutOff === 0) {
			return price;
		} else {
			let potongan = parseFloat(price) - parseFloat(cutOff);
			if (potongan < 0) {
				return 0;
			}
			return parseInt(potongan);
		}
	}

	formatRupiah(angka, prefix) {
		var number_string = String(angka)
				.replace(/[^,\d]/g, "")
				.toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		if (ribuan) {
			let separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
		return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
	}

	render() {
		const price = this.props.price;
		const discount = this.props.discount;
		const cutOff = this.props.cutOff;
		return (
			<div>
				{price !== null ? (
					<div>
						{discount !== null ? (
							<div className="row digit m-0">
								<div className="col-auto p-0" id="priceDetail">
									<s>{this.formatRupiah(price, "Rp. ")}</s>
									<br />
									<b>{this.formatRupiah(this.countDiscount(price, discount), "Rp.")}</b>{" "}
								</div>
								<div className="col p-0 ml-2 d-flex align-items-start animate__animated animate__pulse animate__infinite infinite animate__slow	2s">
									<span className="badge badge-pill badge-primary p-1" style={{ fontSize: "0.8rem" }}>
										-{discount}%
									</span>
								</div>
							</div>
						) : (
							<div>
								{cutOff !== null ? (
									<div className="row digit m-0">
										<div className="col-auto p-0" id="priceDetail">
											<s>{this.formatRupiah(price, "Rp. ")}</s>
											<br />
											<b>{this.formatRupiah(this.countCutOff(price, cutOff), "Rp.")}</b>{" "}
										</div>
									</div>
								) : (
									<div className="digit" id="priceDetail">
										<span>{this.formatRupiah(price, "Rp. ")}</span>
									</div>
								)}
							</div>
						)}

						<UncontrolledTooltip delay={0} placement="right" target="priceDetail">
							Harga per unit
						</UncontrolledTooltip>
					</div>
				) : (
					<span className="digit">Rp. -</span>
				)}
			</div>
		);
	}
}
