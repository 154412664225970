import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Badge } from "reactstrap";
import "react-medium-image-zoom/dist/styles.css";
import fireIcon from "../../assets/img/icons/fire.svg";
import "../../assets/scss/Card.scss";
import Price from "../Price";

class CardProduct extends React.Component {
	render() {
		const baseURL = process.env.REACT_APP_BACKEND_IMAGES;
		const ups = this.props.cable;
		return (
			<div className="mb-3 colProductList-long">
				<Card className="rounded shadow border-0  cardProduct card__one">
					<a href={`/ups/${ups.id}`} tag={Link}>
						{ups.isPopular === 1 ? (
							<span className="notify-badge">
								<div className=" icon-shape text-white p-1 popularIcon img-hover-zoom">
									<img src={fireIcon} className="img-fluid icon-notify" alt=""></img>
								</div>
							</span>
						) : null}

						{ups.image !== null ? (
							<div className="previewProduct">
								<img alt="" src={`${baseURL}/${ups.image}`} className="" />
							</div>
						) : (
							<div className="previewProduct d-flex align-items-center justify-content-center">
								<i>Tidak ada foto produk</i>
							</div>
						)}

						<CardBody className="py-1 px-2 ">
							<div style={titleStyle} className="font-weight-600 crop-text-2">
								{ups.name}
							</div>
							<Price price={ups.price} discount={ups.discount} cutOff={ups.cutOff} />
							<div>
								{ups.backupTime !== null ? (
									<Badge color="danger " pill className="p-1 mr-1 badge-features">
										<i className="fa fa-clock-o mr-1" aria-hidden="true"></i>
										Backup Time : {ups.backupTime} Menit
									</Badge>
								) : null}
								<br />
								{ups.runtime !== null ? (
									<Badge color="warning" pill className="p-1 mr-1 badge-features">
										{ups.runtime}% Full Load
									</Badge>
								) : null}

								<div className="crop-text-2 mt-1" style={{ fontSize: "0.7rem" }}>
									{ups.referensi} - {ups.subCategory} - {ups.powerOutput}
								</div>
								{/* {ups.cutOff !== null ? (
									<Badge color="primary" pill className="p-1 mr-1 badge-features">
										
										Populer
									</Badge>
								) : null} */}
							</div>
						</CardBody>
					</a>
				</Card>
			</div>
		);
	}
}

export default CardProduct;

const titleStyle = {
	fontSize: "0.9rem",
};
