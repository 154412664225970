import React from "react";

import { Badge } from "reactstrap";

class ListItem extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            item:this.props.item,
        }
    }

	render() {
		return (
			<li className="py-2">
				<div className="d-flex align-items-center">
					<div>
						<Badge className="badge-circle mr-3" color="success">
							<i className="ni ni-satisfied" />
						</Badge>
					</div>
					<div>
						<h6 className="mb-0">{this.state.item}</h6>
					</div>
				</div>
			</li>
		);
	}
}
export default ListItem;