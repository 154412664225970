import React from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";

import { Button, UncontrolledCollapse, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, NavLink, Row, Col, Badge } from "reactstrap";

import logoPanjang from "./../../assets/img/brand/logo.png";
import logoAuthorized from "./../../assets/img/brand/se_authorized.png";

import logoMini from "./../../assets/img/brand/logomini.png";
import { connect } from "react-redux";
import { showModalContact } from "../../redux/actions/Index";
import "../../assets/scss/Navbar.scss";

class NavbarKabel extends React.Component {
	componentDidMount() {
		let headroom = new Headroom(document.getElementById("navbar-main"));
		headroom.init();
	}
	state = {
		collapseClasses: "",
		collapseOpen: false,
	};

	onExiting = () => {
		this.setState({
			collapseClasses: "collapsing-out",
		});
	};

	onExited = () => {
		this.setState({
			collapseClasses: "",
		});
	};

	render() {
		return (
			<>
				<header className="header-global" style={{ marginBottom: "80px" }}>
					<Navbar className="navbar-main navbar-transparent navbar-light headroom px-1" expand="lg" id="navbar-main">
						<div className="container-fluid">
							<NavbarBrand className=" logoCompany2 h-100 p-0 " href="/">
								<img alt="..." src={logoPanjang} className="img-fluid " />
							</NavbarBrand>
							<div className="logo-schneider h-100 p-0" href="/">
								<label title="Authorized Distributor">
									<img alt="..." src={logoAuthorized} name="authorized" className="ml-2 " height="50" />
								</label>
							</div>
							<Nav className="ml-auto">
								<button className="navbar-toggler " id="navbar_global" style={{ right: "0" }}>
									<i className="fa fa-bars black fa-lg m-0" style={{ color: "black" }}></i>
								</button>
							</Nav>

							<UncontrolledCollapse toggler="#navbar_global" navbar className={this.state.collapseClasses} onExiting={this.onExiting} onExited={this.onExited}>
								<div className="navbar-collapse-header">
									<Row>
										<Col className="collapse-brand" xs="6">
											<a href="/">
												<img
													alt="..."
													src={logoMini}
													// className="logoCompany"
												/>
											</a>
										</Col>
										<Col className="collapse-close" xs="6">
											<button className="navbar-toggler" id="navbar_global">
												<span />
												<span />
											</button>
										</Col>
									</Row>
								</div>

								<Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
									<NavItem>
										<NavLink className="nav-link-icon" href="/" id="tooltip333589074">
											<span className="nav-link-inner--text" style={{ color: "black", fontSize: "15px" }}>
												Beranda
											</span>
										</NavLink>
									</NavItem>

									<UncontrolledDropdown nav className="mr-0">
										<DropdownToggle nav className="nav-link-icon ">
											<span className="nav-link-inner--text" style={{ color: "black", fontSize: "15px" }}>
												Produk
											</span>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem to="/cable" tag={Link}>
												Kabel
											</DropdownItem>
											<DropdownItem to="/ups" tag={Link}>
												UPS
											</DropdownItem>
											<DropdownItem to="/3m" tag={Link}>
												3M
											</DropdownItem>
											<DropdownItem to="/schneider" tag={Link}>
												Schneider
											</DropdownItem>
											<DropdownItem to="/accessories" tag={Link}>
												Aksesoris
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>

									<NavItem>
										<NavLink className="nav-link-icon" href="/about/#profileCompany" id="tooltip333589074">
											<span className="nav-link-inner--text text2" style={{ color: "black", fontSize: "15px" }}>
												Tentang Kami
											</span>
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink className="nav-link-icon" href="/#howToOrder" id="tooltip333589074">
											<span className="nav-link-inner--text" style={{ color: "black", fontSize: "15px" }}>
												Cara Pemesanan
											</span>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className="nav-link-icon" href="/cek-status" id="tooltip333589074">
											<span className="nav-link-inner--text" style={{ color: "black", fontSize: "15px" }}>
												Cek Status
											</span>
										</NavLink>
									</NavItem>
									<NavItem className=" ml-lg-2">
										<Button className="btn-neutral btn-icon hubungiKami" color="default" onClick={() => this.props.showModalContact(true)}>
											<span className="btn-inner--icon">
												<i className="fa fa-phone mr-2" />
											</span>
											<span className="nav-link-inner--text ml-1 ">Hubungi Kami</span>
										</Button>
									</NavItem>
									<div className=""></div>
								</Nav>
							</UncontrolledCollapse>
							<Nav>
								<NavItem>
									<NavLink className="ml-1" to="/cart" id="tooltip333589074" tag={Link}>
										<i className="fa fa-shopping-basket" aria-hidden="true" style={{ color: "black" }}></i>

										<Badge className="bg-red p-1 ml-1 text-white">{this.props.cart.length}</Badge>
									</NavLink>
								</NavItem>
							</Nav>
						</div>
					</Navbar>
				</header>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		cart: state.cart,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showModalContact: (status) => dispatch(showModalContact(status)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(NavbarKabel);
